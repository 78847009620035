.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}


/* Hide spinner buttons on Chrome, Safari, Edge, and Opera */
input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Hide spinner buttons on Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}



/* Floating Button */
.float-btn {
  position: fixed;
  border-radius: 8px;
  color: white;
  border: none;
  cursor: pointer;
  box-shadow: 0px 7px 8px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s;
  
}


/* Scrollbar Styling */
::-webkit-scrollbar {
  width: 10px;
}
.dropdown::-webkit-scrollbar   {
  width: 5px;
}

::-webkit-scrollbar-track {
  background-color: #d6d6d6;
  -webkit-border-radius: 12px;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  -webkit-border-radius: 12px;
  border-radius: 10px;
  background: #919191; 
}
/* Hover effect on scrollbar thumb */
::-webkit-scrollbar-thumb:hover {
  background: #676767; /* Change color on hover */
}

.dropdown::-webkit-scrollbar-track {
  background-color: #d6d6d6;
  -webkit-border-radius: 12px;
  border-radius: 10px;
}

.dropdown::-webkit-scrollbar-thumb {
  -webkit-border-radius: 12px;
  border-radius: 10px;
  background: #919191; 
}
/* Hover effect on scrollbar thumb */
.dropdown::-webkit-scrollbar-thumb:hover {
  background: #676767; /* Change color on hover */
}
.hide-scrollbar {
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE 10+ */
}

.hide-scrollbar::-webkit-scrollbar {
  display: none; /* Chrome, Safari, Opera */
}